import {
  Configuration,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useAsyncEffect } from '@react-hook/async';
import { addMilliseconds } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { AuthContext } from './contexts/auth-context';
import { ErrorProvider } from './error-provider';

const config: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_AUTH_AUTHORITY] as string[],
    redirectUri: '/login',
    postLogoutRedirectUri: '/login',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;

          case LogLevel.Warning:
            console.warn(message);
            return;

          default:
            return;
        }
      },
    },
  },
} as const;

const instance = new PublicClientApplication(config);

// Default to using the first account if no account is active on page load
if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  instance.setActiveAccount(instance.getAllAccounts()[0]);
}

// // Optional - This will update account state if a user signs in from another tab or window
// instance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
instance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    instance.setActiveAccount(account);
  }
});

export const MsalAuthProvider: React.FC = ({ children }) => {
  return (
    <MsalProvider instance={instance}>
      <MsalAuthContextProvider>{children}</MsalAuthContextProvider>
    </MsalProvider>
  );
};

const scopes = [process.env.REACT_APP_AUTH_BACKEND_SCOPE ?? ''];

const TokenRefreshTime = 10 * 60 * 1000;

export const MsalAuthContextProvider: React.FC = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const appInsights = useAppInsightsContext();

  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [authError, setAuthError] = useState<unknown>();

  const accessTokenRef = useRef(accessToken);

  const isComplete = useMemo(
    () =>
      inProgress === InteractionStatus.None &&
      (!isAuthenticated || accessToken != null),
    [accessToken, inProgress, isAuthenticated]
  );

  const login = useCallback(
    async (queryParameters?: { [key: string]: string }) => {
      await instance.loginRedirect({
        scopes,
        extraQueryParameters: queryParameters,
      });
    },
    [instance]
  );

  const logout = useCallback(async () => {
    // Attempt promptless logout.
    await instance.logout({ account: instance.getActiveAccount() });
  }, [instance]);

  const getAccessToken = useCallback(async () => {
    const account = instance.getActiveAccount();

    if (account == null) {
      console.warn('No active msal account.');
      setAccessToken(undefined);
      return;
    }

    const request = {
      scopes,
      account,
      forceRefresh: false,
    };

    try {
      let authResult = await instance.acquireTokenSilent(request);

      // Force refresh if current token expires before next refresh.
      if (
        authResult.expiresOn != null &&
        authResult.expiresOn < addMilliseconds(new Date(), TokenRefreshTime)
      ) {
        authResult = await instance.acquireTokenSilent({
          ...request,
          forceRefresh: true,
        });
      }

      accessTokenRef.current = authResult.accessToken;
      setAccessToken(authResult.accessToken);

      return authResult.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
        return;
      }

      // Wrap error in an Error object if needed for tracking with AppInsights.
      const exception =
        error instanceof Error
          ? error
          : new Error('Unknown login error', { cause: error });

      appInsights.trackException({
        exception,
        severityLevel: SeverityLevel.Error,
      });

      setAuthError(error);
    }
  }, [appInsights, instance]);

  useAsyncEffect(async () => {
    if (!isAuthenticated) {
      return;
    }

    await getAccessToken();

    const id = setInterval(getAccessToken, TokenRefreshTime);
    return () => clearInterval(id);
  }, [getAccessToken, isAuthenticated]);

  return (
    <ErrorProvider error={authError}>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          isComplete,
          accessToken,
          accessTokenRef,
          getAccessToken,
          login,
          logout,
        }}
      >
        {children}
      </AuthContext.Provider>
    </ErrorProvider>
  );
};
