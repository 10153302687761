import { useContext } from 'react';

import { ErrorsContext } from './contexts/errors-context';

interface ErrorProviderProps {
  error?: any;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({
  children,
  error,
}) => {
  const errors = useContext(ErrorsContext);

  if (error == null) {
    return <>{children}</>;
  }

  return (
    <ErrorsContext.Provider value={[...(errors ?? []), error]}>
      {children}
    </ErrorsContext.Provider>
  );
};
