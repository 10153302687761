import React, { useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { useRequiredContext } from './common/hooks-util';
import { AppInsightsInstanceContext } from './contexts/app-insights-instance-context';
import { AuthContext } from './contexts/auth-context';
import { UserContext, UserContextProps } from './contexts/user-context';
import { ErrorProvider } from './error-provider';
import { UserService } from './generated/tellus';

export const UserProvider: React.FC = ({ children }) => {
  const { isAuthenticated, isComplete } = useRequiredContext(AuthContext);
  const appInsights = useRequiredContext(AppInsightsInstanceContext);

  const { data, error, isLoading, mutate } = useSWR(
    isComplete && isAuthenticated && { _key: 'Me' },
    UserService.getUser
  );

  useEffect(() => {
    if (data != null) {
      appInsights.setAuthenticatedUserContext(data.id.toString());
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
  }, [appInsights, data]);

  const user = useMemo((): UserContextProps | undefined => {
    if (isLoading || data == null) {
      return;
    }

    return {
      id: data.id.toString(),
      email: data.email,
      phonenumber: data.phonenumber,
      name: data.name,
      role: data.role,
      allowedProjectIds: data.allowedProjectIds,
      allowedMeasuringPoints: data.allowedMeasuringPoints,
      updateUser: () => mutate(),
    };
  }, [data, isLoading, mutate]);

  return (
    <ErrorProvider error={error}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </ErrorProvider>
  );
};
