import { Query } from '@apollo/client/react/components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import ErrorMessage from './error-message';

const QueryWrapper = (props) => {
  const appInsights = useAppInsightsContext();

  return (
    <Query {...props}>
      {(result) => {
        if (result.error) {
          appInsights.trackException({
            exception: result.error,
            severityLevel: SeverityLevel.Warning,
          });

          return (
            <ErrorMessage error={result.error} {...props.errorMessageProps} />
          );
        }

        return props.children(result);
      }}
    </Query>
  );
};

export default QueryWrapper;
