export const notEmpty = <T>(value: T | null | undefined): value is T =>
  value != null;

export const EpochDate = new Date(0);

export const formatMeasuringPointNumber = (number: number) =>
  number.toString().replace(',', '.');

export interface NamedValue<T = any> {
  value: T;
  name: string;
}

export const stringCompare = (lhs?: string | null, rhs?: string | null) => {
  if (lhs == null) {
    if (rhs == null) {
      return 0;
    }
    return 1;
  }

  if (rhs == null) {
    return -1;
  }

  return lhs.localeCompare(rhs);
};

export const stringSort = <T>(
  items: T[],
  key: (item: T) => string | undefined | null
) => [...items].sort((lhs, rhs) => stringCompare(key(lhs), key(rhs)));

export const asArray = <T>(values: T | T[]) =>
  Array.isArray(values) ? values : [values];

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.target = '_blank';
  link.click();
};
