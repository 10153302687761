import { useApolloClient } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/sv';
import React, { Suspense, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useLocation,
} from 'react-router-dom';

import {
  CircularProgress,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import '@progress/kendo-theme-material/dist/all.css';

import { useRequiredContext } from '../common/hooks-util';
import ErrorBoundary from '../components/error-boundary';
import Header from '../components/navigation/header';
import SideNavigation from '../components/navigation/side-navigation';
import SearchResults from '../components/search-results';
import { AuthContext } from '../contexts/auth-context';
import { DaemonProvider } from '../contexts/daemon-context';
import { ErrorsContext } from '../contexts/errors-context';
import ProjectContext from '../contexts/project-context';
import { UploadProvider } from '../contexts/upload-context';
import { UserContext } from '../contexts/user-context';
import NotFoundPage from './not-found';
import { AdminPolicy } from './policies';
import routes from './project/routes';
import globalRoutes from './routes';
import UnauthorizedPage from './unauthorized';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '@media print': {
      marginTop: 0,
    },
  },
  headerWrapper: {
    width: '100%',
  },
  globalNavigation: {
    position: 'absolute',
    top: '64px',
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sideNavigation: {
    width: 280,
    height: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    padding: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
    '@media print': {
      marginTop: 0,
      padding: 0,
      overflowY: 'hidden',
    },
  },
  div: {
    margin: '0 auto',
    maxWidth: 700,
    width: '100%',
  },
}));

const Index = () => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const widthDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { isAuthenticated, isComplete: isAuthComplete } =
    useRequiredContext(AuthContext);
  const user = useContext(UserContext);
  const errors = useContext(ErrorsContext);

  const client = useApolloClient();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [project, setProject] = useState(null);

  const currentRoute = useMemo(
    () =>
      globalRoutes.concat(routes).find((r) => matchPath(location.pathname, r)),
    [location.pathname]
  );

  const projectValue = useMemo(
    () => ({
      project: project,
      setProject: (newProject) => setProject(newProject),
    }),
    [project]
  );

  // Do not show the loading prompt if there are errors
  // Show loading if authentication is in progress
  // or if we are authenticated and waiting for the user to be loaded.
  if (
    errors == null &&
    (!isAuthComplete || (isAuthenticated && user == null))
  ) {
    return (
      <>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ height: '100%' }}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <UploadProvider>
      <DaemonProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <ProjectContext.Provider value={projectValue}>
            <Helmet>
              <title>TellUs</title>
            </Helmet>

            {user != null && !currentRoute?.hideHeader && (
              <Header
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                currentRoute={currentRoute}
                client={client}
              />
            )}

            <div className={!currentRoute.hideHeader ? classes.root : ''}>
              {user != null && (widthDown || currentRoute?.hasMenu) && (
                <nav className={classes.sideNavigation}>
                  <SideNavigation
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                  />
                </nav>
              )}
              <main className={classes.content} id='main'>
                <ErrorBoundary>
                  <div className={classes.div}>
                    {AdminPolicy.includes(user?.role) && <SearchResults />}
                  </div>
                  <Suspense fallback=''>
                    <Switch>
                      {globalRoutes.map(
                        ({ exact, path, component, name, allowedRoles }) =>
                          allowedRoles ? (
                            <ProtectedRoute
                              exact={exact}
                              path={path}
                              component={component}
                              key={name}
                              allowedRoles={allowedRoles}
                            />
                          ) : (
                            <Route
                              exact={exact}
                              path={path}
                              component={component}
                              key={name}
                            />
                          )
                      )}
                      <Route component={NotFoundPage} />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </main>
            </div>
          </ProjectContext.Provider>
        </MuiPickersUtilsProvider>
      </DaemonProvider>
    </UploadProvider>
  );
};

export default Index;

const ProtectedRoute = ({
  component: Component,
  allowedRoles: roles,
  ...rest
}) => {
  const { isAuthenticated } = useRequiredContext(AuthContext);
  const errors = useContext(ErrorsContext);
  const location = useLocation();

  const user = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated || errors != null) {
          return <Redirect push to={`/login?path=${location.pathname}`} />;
        }

        return roles.includes(user?.role) ? (
          <Component {...props} />
        ) : (
          <UnauthorizedPage />
        );
      }}
    />
  );
};
