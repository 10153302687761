/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MeasurementDtoDataResponse } from '../models/MeasurementDtoDataResponse';
import type { WorkbenchDto } from '../models/WorkbenchDto';
import type { WorkbenchDtoDataResponse } from '../models/WorkbenchDtoDataResponse';
import type { WorkbenchFilterDto } from '../models/WorkbenchFilterDto';
import type { WorkbenchFilterDtoDataResponse } from '../models/WorkbenchFilterDtoDataResponse';
import type { WorkbenchMutationDto } from '../models/WorkbenchMutationDto';
import type { WorkbenchSourceDto } from '../models/WorkbenchSourceDto';
import type { WorkbenchSourceDtoDataResponse } from '../models/WorkbenchSourceDtoDataResponse';
import type { WorkbenchSourceMutationDto } from '../models/WorkbenchSourceMutationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkbenchService {

  /**
   * @returns WorkbenchDtoDataResponse OK
   * @throws ApiError
   */
  public static listWorkbenches({
    projectReference,
    after,
    before,
    first,
    last,
  }: {
    projectReference: string,
    after?: string,
    before?: string,
    first?: number,
    last?: number,
  }): CancelablePromise<WorkbenchDtoDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{projectReference}/workbenches',
      path: {
        'projectReference': projectReference,
      },
      query: {
        'after': after,
        'before': before,
        'first': first,
        'last': last,
      },
    });
  }

  /**
   * @returns WorkbenchDto OK
   * @throws ApiError
   */
  public static createWorkbench({
    projectReference,
    requestBody,
  }: {
    projectReference: string,
    requestBody?: WorkbenchMutationDto,
  }): CancelablePromise<WorkbenchDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/projects/{projectReference}/workbenches',
      path: {
        'projectReference': projectReference,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WorkbenchDto OK
   * @throws ApiError
   */
  public static getWorkbench({
    projectReference,
    workbenchId,
  }: {
    projectReference: string,
    workbenchId: string,
  }): CancelablePromise<WorkbenchDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateWorkbench({
    projectReference,
    workbenchId,
    requestBody,
  }: {
    projectReference: string,
    workbenchId: string,
    requestBody?: WorkbenchMutationDto,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WorkbenchSourceDtoDataResponse OK
   * @throws ApiError
   */
  public static listWorkbenchSources({
    projectReference,
    workbenchId,
    after,
    before,
    first,
    last,
  }: {
    projectReference: string,
    workbenchId: string,
    after?: string,
    before?: string,
    first?: number,
    last?: number,
  }): CancelablePromise<WorkbenchSourceDtoDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/sources',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
      },
      query: {
        'after': after,
        'before': before,
        'first': first,
        'last': last,
      },
    });
  }

  /**
   * @returns WorkbenchSourceDto OK
   * @throws ApiError
   */
  public static createWorkbenchSource({
    projectReference,
    workbenchId,
    requestBody,
  }: {
    projectReference: string,
    workbenchId: string,
    requestBody?: WorkbenchSourceMutationDto,
  }): CancelablePromise<WorkbenchSourceDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/sources',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WorkbenchSourceDto OK
   * @throws ApiError
   */
  public static getWorkbenchSource({
    projectReference,
    workbenchId,
    sourceId,
  }: {
    projectReference: string,
    workbenchId: string,
    sourceId: string,
  }): CancelablePromise<WorkbenchSourceDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/sources/{sourceId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
        'sourceId': sourceId,
      },
    });
  }

  /**
   * @returns WorkbenchSourceDto OK
   * @throws ApiError
   */
  public static updateWorkbenchSource({
    projectReference,
    workbenchId,
    sourceId,
    requestBody,
  }: {
    projectReference: string,
    workbenchId: string,
    sourceId: string,
    requestBody?: WorkbenchSourceMutationDto,
  }): CancelablePromise<WorkbenchSourceDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/sources/{sourceId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
        'sourceId': sourceId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteWorkbenchSource({
    projectReference,
    workbenchId,
    sourceId,
  }: {
    projectReference: string,
    workbenchId: string,
    sourceId: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/sources/{sourceId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
        'sourceId': sourceId,
      },
    });
  }

  /**
   * @returns WorkbenchFilterDtoDataResponse OK
   * @throws ApiError
   */
  public static listWorkbenchFilters({
    projectReference,
    workbenchId,
    after,
    before,
    first,
    last,
  }: {
    projectReference: string,
    workbenchId: string,
    after?: string,
    before?: string,
    first?: number,
    last?: number,
  }): CancelablePromise<WorkbenchFilterDtoDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/filters',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
      },
      query: {
        'after': after,
        'before': before,
        'first': first,
        'last': last,
      },
    });
  }

  /**
   * @returns WorkbenchFilterDto OK
   * @throws ApiError
   */
  public static createWorkbenchFilter({
    projectReference,
    workbenchId,
    requestBody,
  }: {
    projectReference: string,
    workbenchId: string,
    requestBody?: WorkbenchFilterDto,
  }): CancelablePromise<WorkbenchFilterDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/filters',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns WorkbenchFilterDto OK
   * @throws ApiError
   */
  public static getWorkbenchFilter({
    projectReference,
    workbenchId,
    filterId,
  }: {
    projectReference: string,
    workbenchId: string,
    filterId: string,
  }): CancelablePromise<WorkbenchFilterDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/filters/{filterId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
        'filterId': filterId,
      },
    });
  }

  /**
   * @returns WorkbenchFilterDto OK
   * @throws ApiError
   */
  public static updateWorkbenchFilter({
    projectReference,
    workbenchId,
    filterId,
    requestBody,
  }: {
    projectReference: string,
    workbenchId: string,
    filterId: string,
    requestBody?: WorkbenchFilterDto,
  }): CancelablePromise<WorkbenchFilterDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/filters/{filterId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
        'filterId': filterId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static deleteWorkbenchFilter({
    projectReference,
    workbenchId,
    filterId,
  }: {
    projectReference: string,
    workbenchId: string,
    filterId: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/filters/{filterId}',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
        'filterId': filterId,
      },
    });
  }

  /**
   * @returns MeasurementDtoDataResponse OK
   * @throws ApiError
   */
  public static listWorkbenchMeasurements({
    projectReference,
    workbenchId,
    after,
    before,
    first,
    last,
  }: {
    projectReference: string,
    workbenchId: string,
    after?: string,
    before?: string,
    first?: number,
    last?: number,
  }): CancelablePromise<MeasurementDtoDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{projectReference}/workbenches/{workbenchId}/measurements',
      path: {
        'projectReference': projectReference,
        'workbenchId': workbenchId,
      },
      query: {
        'after': after,
        'before': before,
        'first': first,
        'last': last,
      },
    });
  }

}
